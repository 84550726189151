
import Layout from '@/admin/layouts/Default.vue';
import TitleReturn from '@/components/TitleReturn.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import { Component, Vue } from 'vue-property-decorator';
import ContentModule from '@/admin/store/content';
import { tabsNavInterface } from '@/interfaces/tabs';

@Component({
    components: {
        Layout,
        TitleReturn,
        TabsNav,
        InProgress: () => import('./_inProgress.vue'),
        NewsList: () => import('./news/Table.vue'),
        NewsCard: () => import('./news/Card.vue'),
        Terms: () => import('./Terms.vue'),
        Offer: () => import('./Offer.vue'),
        FaqList: () => import('./faq/Table.vue'),
        FaqCard: () => import('./faq/Card.vue'),
        AboutUs: () => import('./AboutUs.vue'),
        Policy: () => import('./Policy.vue'),
        Rules: () => import('./Rules.vue'),
        MainPage: () => import('./MainPage/Index.vue'),
        SliderCard: () => import('./MainPage/Card.vue'),
    },
})
export default class ContentComponent extends Vue {
    contentNewsRouteNames = ['content', 'content_news'];
    sliderCardRouteNames = ['slider_content_card', 'slider_content_card_create'];
    titleDict: { [key: string]: string } = {
        content: 'Новости',
        content_news: 'Новости',
        content_news_card: 'Редактирование новости',
        content_news_card_create: 'Создание новости',
        content_terms: 'Пользовательское соглашение',
        content_offer: 'Публичная оферта',
        content_faq: 'FAQ',
        content_faq_card: 'FAQ',
        content_faq_card_create: 'Создание FAQ',
        content_policy: 'Политика конфиденциальности',
        content_about: 'О нас',
        content_main_page: 'Главная страница',
        content_rules: 'Правила',
    };

    get tabsNav(): tabsNavInterface {
        return ContentModule.tabsNav;
    }

    get title(): string {
        return this.titleDict[this.$route.name as string];
    }

    get currentTab(): string {
        if (this.$route.name === 'content') {
            return Object.values(ContentModule.tabsNav)[0].id;
        }

        const tabName = this.$route.name?.split('_')[1];
        const key = Object.keys(ContentModule.tabsNav).filter((key) => key.indexOf(tabName as string) >= 0)[0];

        return key;
    }

    get isReturnBeforeHidden(): boolean {
        if (this.$route.name === 'content_main_page') {
            return true;
        }

        return !!Object.keys(this.titleDict).filter(
            (key) => key === this.$route.name && key.split('_').length === 2,
        )[0];
    }

    returnBefore(): void {
        if (this.isReturnBeforeHidden) {
            return;
        }

        const routeName = Object.keys(this.titleDict)
            .filter((key) => key === this.$route.name && key.split('_').length > 2)
            .map((key) => key.split('_').splice(0, 2).join('_'))[0];

        this.$router.push({ name: routeName });
    }

    created(): void {
        ContentModule.initTabsItems();
    }
}
