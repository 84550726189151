export const tabsNav = {
    news: {
        id: 'news',
        name: 'Новости',
        template: 'news',
        pathName: 'content_news',
        icon: '',
        iconSize: '16',
    },
    main_page: {
        id: 'main_page',
        name: 'Главная страница',
        template: 'main_page',
        pathName: 'content_main_page',
        icon: '',
        iconSize: '16',
    },
    about: {
        id: 'about',
        name: 'О нас',
        template: 'about',
        pathName: 'content_about',
        icon: '',
        iconSize: '16',
    },
    rules: {
        id: 'rules',
        name: 'Правила',
        template: 'rules',
        pathName: 'content_rules',
        icon: '',
        iconSize: '16',
    },
    faq: {
        id: 'faq',
        name: 'FAQ',
        template: 'faq',
        pathName: 'content_faq',
        icon: '',
        iconSize: '16',
    },
    policy: {
        id: 'policy',
        name: 'Политика конфиденциальности',
        template: 'policy',
        pathName: 'content_policy',
        icon: '',
        iconSize: '16',
    },
    terms: {
        id: 'terms',
        name: 'Пользовательское соглашение',
        template: 'terms',
        pathName: 'content_terms',
        icon: '',
        iconSize: '16',
    },
    offer: {
        id: 'offer',
        name: 'Публичная оферта',
        template: 'offer',
        pathName: 'content_offer',
        icon: '',
        iconSize: '16',
    },
};
